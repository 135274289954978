import React, { useContext } from "react";
import { useTheme } from "styled-components";
import { HeaderContext } from "../../../context/HeaderContext";
import S from "./styles";
import { ColourScheme } from '../../../types';

export interface ConnectButtonProps {
  colourScheme: ColourScheme;
  scrolled: boolean;
}

const ConnectButton = ({ colourScheme, scrolled }: ConnectButtonProps) => {
  const { headerColor } = useContext(HeaderContext);
  const theme = useTheme();

  return (
    <S.ConnectButton
      cover
      direction="up"
      bg={theme.colors.darkGrey1}
      className="connect"
      to="/contact-us"
      colourScheme={colourScheme}
      scrolled={scrolled}
    >
      Connect
    </S.ConnectButton>
  );
};

export default ConnectButton;

import React, { useContext } from "react";
import { useTheme } from "styled-components";
import { HeaderContext } from "../../../context/HeaderContext";
import HeaderLogo from "../../common/HeaderLogo";
import S from "./styles";

const Logo = props => {
  const { headerColor } = useContext(HeaderContext);
  const theme = useTheme();

  return (
    <S.Logo cover direction="up" to="/" bg={theme.colors.darkGrey1} {...props}>
      <HeaderLogo colourScheme={headerColor} />
    </S.Logo>
  );
};

export default Logo;

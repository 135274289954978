import styled from "styled-components";

const HamburgerButton = styled.button`
  background: transparent;
  border: none;
  z-index: 1000;
  color: ${({ theme, colourScheme }) => colourScheme === "dark" ? theme.colors.white1 : theme.colors.darkGrey1};
  width: 30px;
  height: 22px;
  position: relative;
  margin-left: 20px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  outline: none;

  &.open {
    span {
      &:nth-child(1) {
        top: 9px;
        width: 0%;
        left: 50%;
      }

      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 100%;
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        width: 100%;
      }

      &:nth-child(4) {
        top: 9px;
        width: 0%;
        left: 50%;
      }
    }
  }

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: ${({ theme, colourScheme }) => colourScheme === "dark" ? theme.colors.white1 : theme.colors.darkGrey1};
    border-radius: 2px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 9px;
      width: 30px;
      right: 0;
      left: auto;
    }

    &:nth-child(4) {
      top: 18px;
    }


  }
`;

export default {
  HamburgerButton,
};

import styled from "styled-components";

const NewsletterSignup = styled.form`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  margin-bottom: 15px;
  margin-top: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkGrey};
  width: 360px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding-top: 40px;
    width: 100%;
  }

  .success,
  input {
    outline: none;
    border: none;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.darkGrey};
    font-family: ${({ theme }) => theme.fonts.ttNormsRegular};
    font-size: 15px;
    letter-spacing: 0.2px;
    line-height: 28px;
    margin: 0 24px 0 0;
    flex: 1;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      margin: 0;
    }
  }

  .submit {
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;

    svg {
      right: 0px;
    }
  }

  .error {
    position: absolute;
    bottom: -25px;
    font-size: 14px;
    color: red;
  }
`;

export default {
  NewsletterSignup,
};

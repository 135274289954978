import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import S from "./styles";
import { createMenuStructure } from "../../../utils/helpers";
import { SettingsContext } from "../../../context/SettingsContext";
import NewsletterSignup from "../../common/NewsletterSignup";
import LinkedinIcon from "../../common/LinkedinIcon";
import YouTubeIcon from "../../common/YouTubeIcon";

const FooterMenu = () => {
  const { data } = useStaticQuery(graphql`
    query FooterMenuQuery {
      data: allContentfulMenu(
        filter: { position: { eq: "footer" } }
        sort: { fields: order, order: ASC }
      ) {
        edges {
          node {
            heading
            order
            items {
              id
              title
              slug
              linkType
              contentLink {
                ... on ContentfulPage {
                  contentful_id
                }
                ... on ContentfulPasswordProtectedPage {
                  contentful_id
                }
              }
              externalLink
              items {
                id
                title
                slug
                linkType
                externalLink
                contentLink {
                  ... on ContentfulPage {
                    contentful_id
                  }
                  ... on ContentfulPasswordProtectedPage {
                    contentful_id
                  }
                }
                items {
                  id
                  title
                  slug
                  linkType
                  externalLink
                  contentLink {
                    ... on ContentfulPage {
                      contentful_id
                    }
                    ... on ContentfulPasswordProtectedPage {
                      contentful_id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  if (!data || !data.edges) {
    return null;
  }

  const {
    settings: { pageMap, linkedinUrl, youTubeUrl, mailchimpSubscribeEndpoint },
  } = useContext(SettingsContext);
  const date = new Date();

  return (
    <>
      <S.FooterMenu>
        {data.edges.map((menu, index) => (
          <div className="footer-menu" key={index}>
            <div className="menu-inner">
              {menu.node.heading && (
                <div className="title">{menu.node.heading}</div>
              )}
              {createMenuStructure(menu.node.items, "", {}, pageMap)}
            </div>
            {index === data.edges.length - 1 && (
              <div className="signup-social">
                {mailchimpSubscribeEndpoint && <NewsletterSignup />}
                {(linkedinUrl || youTubeUrl) && (
                  <div className="social">
                    {linkedinUrl && (
                      <a className="linkedin" href={linkedinUrl}>
                        <LinkedinIcon colourScheme="light" />
                      </a>
                    )}
                    {youTubeUrl && (
                      <a className="youtube" href={youTubeUrl}>
                        <YouTubeIcon colourScheme="light" />
                      </a>
                    )}
                  </div>
                )}
                <p className="copyright">
                  Copyright&copy; {date.getFullYear()} JVAT. All Rights Reserved
                </p>
              </div>
            )}
          </div>
        ))}
      </S.FooterMenu>
    </>
  );
};

export default FooterMenu;

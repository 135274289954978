import React from "react";
import { useTheme } from "styled-components";
import S from "./styles";
import { ColourScheme } from "../../../types";

interface LinkedinIconProps {
  color?: string;
  colourScheme?: ColourScheme;
  [prop: string]: any;
}

const LinkedinIcon = ({ colourScheme, color, ...rest }: LinkedinIconProps) => {
  const theme = useTheme();

  const strokeColour =
    colourScheme === "dark" ? theme.colors.white1 : theme.colors.darkGrey1;

  return (
    <S.LinkedinIcon
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      {...rest}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={color || strokeColour} transform="translate(-50 -1132)">
          <g transform="translate(50 1132)">
            <g>
              <path d="M3.592 0a3.587 3.587 0 010 7.172A3.59 3.59 0 010 3.583 3.588 3.588 0 013.592 0zM.493 29.788H6.69v-19.9H.493v19.9z"></path>
              <path d="M16.503 9.888v2.723h.08c.828-1.567 2.845-3.215 5.858-3.215 6.262 0 7.417 4.118 7.417 9.478v10.914h-6.181v-9.675c0-2.31-.045-5.277-3.215-5.277-3.22 0-3.711 2.513-3.711 5.109v9.843h-6.182v-19.9h5.934z"></path>
            </g>
          </g>
        </g>
      </g>
    </S.LinkedinIcon>
  );
};

export default LinkedinIcon;

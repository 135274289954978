import { DefaultTheme } from "styled-components";

interface Colors {
  [color: string]: string;
}
const colors: Colors = {
  lightGrey1: "#E5E5E5",
  lightGrey2: "#ADAEAD",
  mediumGrey1: "#5B5C5B",
  mediumGrey2: "#979797",
  darkGrey1: "#2D3134",
  darkGrey2: "#2A2C2C",
  green1: "#00BF6F",
  green2: "#8BEBB3",
  white1: "#FFFFFF",
  white2: "#F0F0F0",
};

interface Fonts {
  [font: string]: string;
}
const fonts: Fonts = {
  helveticaRegular: "Helvetica-Regular",
  helveticaMedium: "Helvetica-Medium",
  helveticaBold: "Helvetica-Bold",
  poppinsRegular: "Poppins-Regular",
  poppinsSemiBold: "Poppins-SemiBold",
  poppinsBold: "Poppins-Bold",
  rajdhaniSemiBold: "Rajdhani-SemiBold",
};

interface Breakpoints {
  [breakpoint: string]: string;
}
const breakpoints: Breakpoints = {
  small: "750px",
  medium: "1200px",
  large: "1800px",
};

interface Mixins {
  [mixin: string]: any;
}
const mixins: Mixins = {
  headingGreenUnderline: `
    padding-bottom: 20px;

    @media screen and (max-width: ${breakpoints.medium}) {
      padding-bottom: 10px;
      
      &:after {
        height: 2px
      }
    }

    &:after {
        position: absolute;
        left: 0;
        bottom: 0;
        content: "";
        width: 105px;
        height: 3px;
        background-color: ${colors.green1};
    }
  `,
  headingAnimation: ({
    colourScheme,
    revealDelay = 0,
    revealLength = 0.5,
    revealStep = 0.2,
    shrinkDelay = 0.5,
    shrinkLength = 0.5,
    shrinkStep = 0.2,
  }) => {
    return `
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow: hidden;

      span {
        transform: translateX(-100%);
      }

      &:after {
        transform: translateX(-100%);
      }

      &.animate {
        span {
          animation: reveal ${revealLength}s ${revealDelay}s ease forwards;
          overflow: hidden;
          display: inline-block;
      
          &:nth-child(2) {
            animation-delay: ${revealStep * 1}s;
      
            &:before {
              animation-delay: ${revealDelay + shrinkDelay + shrinkStep * 1}s;
            }
          }
      
          &:nth-child(3) {
            animation-delay: ${revealStep * 2}s;
      
            &:before {
              animation-delay: ${revealDelay + shrinkDelay + shrinkStep * 2}s;
            }
          }
      
          &:nth-child(4) {
            animation-delay: ${revealStep * 3}s;
      
            &:before {
              animation-delay: ${revealDelay + shrinkDelay + shrinkStep * 3}s;
            }
          }
          
          &:before {
              position: absolute;
              width: 100%;
              height: 100%;
              content: "";
              background-color: ${
                colourScheme === "dark" ? colors.white1 : colors.darkGrey1
              };
              top: 0;
              right: 0;
              animation: shrink ${shrinkLength}s ${
      revealDelay + shrinkDelay
    }s ease forwards;
              z-index: 2;
          }
        }
    
        &:after {
          transform: translateX(-100%);
          animation: translateBorder 0.5s 0.75s ease forwards;
        }
      }

      @keyframes reveal {
          0% {
              transform: translateX(-100%);
          }
          100% {
              transform: translateX(0%);
          }
      }

      @keyframes shrink {
          0% {
              width: 100%;
          }
          100% {
              width: 0%;
          }
      }

      @keyframes translateBorder {
        0% {
            trasform: translateX(-100%);
        }
        100% {
            transform: translateX(0);
        }
      }
    `;
  },
  textAnimation: (duration = 0.5, delay = 0) => {
    return `
      transform: translateY(20px);
      opacity: 0;

      &.animate {
        animation: fadeInUp ${duration}s ${delay}s ease-out forwards;
      }

      @keyframes fadeInUp {
        0% {
          opacity: 0;
          transform: translateY(20px);
        }
        100% {
          opacity: 1;
          transform: translateY(0px);
        }
      }
    `;
  },
};

export interface Theme extends DefaultTheme {
  colors: Colors;
  fonts: Fonts;
  breakpoints: Breakpoints;
  mixins: Mixins;
}

export const theme: Theme = {
  colors,
  fonts,
  breakpoints,
  mixins,
};

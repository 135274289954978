import React from "react";
import S from "./styles";

const HamburgerButton = ({ ...rest }) => {
  return (
    <S.HamburgerButton {...rest}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </S.HamburgerButton>
  );
};

export default HamburgerButton;

import React from "react";
import S from "./styles";

export interface ContainerProps {
  children: React.ReactElement;
  [prop: string]: any;
}

const Container = ({ children, ...rest }: ContainerProps) => {
  return (
    <S.Container className="container" {...rest}>
      {children}
    </S.Container>
  );
};

export default Container;

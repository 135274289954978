import React from "react";
import { useTheme } from "styled-components";
import S from "./styles";
import { ColourScheme } from "../../../types";

interface SpinnerIconProps {
  color?: string;
  colourScheme?: ColourScheme;
  [prop: string]: any;
}

const SpinnerIcon = ({ colourScheme, color, ...rest }: SpinnerIconProps) => {
  const theme = useTheme();

  const strokeColour =
    colourScheme === "dark" ? theme.colors.white1 : theme.colors.darkGrey1;

  return (
    <S.SpinnerIcon
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: "auto", background: "transparent" }}
      width="40"
      height="40"
      display="block"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
      {...rest}
    >
      <circle
        cx="50"
        cy="50"
        r="41"
        fill="none"
        stroke={color || strokeColour}
        strokeDasharray="193.20794819577225 66.40264939859075"
        strokeWidth="3"
      >
        <animateTransform
          attributeName="transform"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          type="rotate"
          values="0 50 50;360 50 50"
        ></animateTransform>
      </circle>
    </S.SpinnerIcon>
  );
};

export default SpinnerIcon;

import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { useTheme } from "styled-components";
import LinkedinIcon from "../../common/LinkedinIcon";
import YouTubeIcon from "../../common/YouTubeIcon";
import NewsletterSignup from "../../common/NewsletterSignup";
import { HeaderContext } from "../../../context/HeaderContext";
import { SettingsContext } from "../../../context/SettingsContext";
import { createMenuStructure } from "../../../utils/helpers";
import S from "./styles";
import Container from "../Container";
import { theme } from "../../../styles/theme";

export interface MainMenuProps {
  scrolled: boolean;
  open: boolean;
  setMenuOpen: any;
}

const MainMenu = ({ scrolled, open, setMenuOpen }: MainMenuProps) => {
  const theme = useTheme();
  const { headerColor } = useContext(HeaderContext);
  const {
    settings: {
      pageMap,
      contactPageSlug,
      contactEmail,
      linkedinUrl,
      youTubeUrl,
      mailchimpSubscribeEndpoint,
    },
  } = useContext(SettingsContext);

  const { data } = useStaticQuery(graphql`
    query MainMenuQuery {
      data: contentfulMenu(position: { eq: "main" }) {
        items {
          id
          title
          slug
          linkType
          contentLink {
            ... on ContentfulPage {
              contentful_id
            }
            ... on ContentfulPasswordProtectedPage {
              contentful_id
            }
          }
          externalLink
          items {
            id
            title
            slug
            linkType
            externalLink
            contentLink {
              ... on ContentfulPage {
                contentful_id
              }
              ... on ContentfulPasswordProtectedPage {
                contentful_id
              }
            }
            items {
              id
              title
              slug
              linkType
              externalLink
              contentLink {
                ... on ContentfulPage {
                  contentful_id
                }
                ... on ContentfulPasswordProtectedPage {
                  contentful_id
                }
              }
            }
          }
        }
      }
    }
  `);

  if (!data || !data.items) {
    return null;
  }

  return (
    <S.MainMenu headerColor={headerColor} scrolled={scrolled} open={open}>
      <Container>
        <div className="inner">
          <div className="left">
            {contactPageSlug && (
              <AniLink
                direction="up"
                cover
                bg={theme.colors.darkGrey1}
                to={contactPageSlug}
                onClick={() => {
                  setMenuOpen(false);
                  document.body.classList.remove("fixed");
                }}
              >
                Contact Us
              </AniLink>
            )}
            {contactEmail && (
              <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
            )}
            {mailchimpSubscribeEndpoint && <NewsletterSignup />}
            {(linkedinUrl || youTubeUrl) && (
              <div className="social">
                {linkedinUrl && (
                  <a className="linkedin" href={linkedinUrl}>
                    <LinkedinIcon colourScheme="light" />
                  </a>
                )}
                {youTubeUrl && (
                  <a className="youtube" href={youTubeUrl}>
                    <YouTubeIcon colourScheme="light" />
                  </a>
                )}
              </div>
            )}
          </div>
          <div className="right">
            {createMenuStructure(
              data.items,
              "",
              {
                onClick: () => {
                  setMenuOpen(false);
                  document.body.classList.remove("fixed");
                },
              },
              pageMap
            )}
          </div>
        </div>
      </Container>
    </S.MainMenu>
  );
};

export default MainMenu;

import React from "react";
import { useTheme } from "styled-components";
import S from "./styles";
import { ColourScheme } from "../../../types/interfaces";

interface ArrowIconProps {
  color?: string;
  colourScheme?: ColourScheme;
  [prop: string]: any;
}

const ArrowIcon = ({ colourScheme, color, ...rest }: ArrowIconProps) => {
  const theme = useTheme();

  const strokeColour =
    colourScheme === "dark" ? theme.colors.white1 : theme.colors.darkGrey1;

  return (
    <S.ArrowIcon
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="32"
      viewBox="0 0 31 32"
      {...rest}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g
          stroke={color || strokeColour}
          strokeWidth="2"
          transform="translate(-305 -3438)"
        >
          <g transform="translate(139 3050)">
            <g transform="translate(1 389.5)">
              <g transform="translate(165)">
                <path
                  strokeLinecap="square"
                  d="M2.544 14.794L28.721 14.794"
                ></path>
                <path
                  d="M4.51354043 25.0882353L24.8628051 25.0882353 24.8628051 4.5"
                  transform="rotate(-45 14.688 14.794)"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </S.ArrowIcon>
  );
};

export default ArrowIcon;

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    font-variant-ligatures: none;
  }

  html,
  body {
    width: 100%;
    height: 100%;
    font-family: 'Poppins-Regular', sans-serif;
  }
  
  body {
    &.fixed {
      overflow: hidden;
      padding-right: 15px;

      @media screen and (max-width: 1200px) {
        padding-right: 0;
      }
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Helvetica-Medium', sans-serif;
    font-weight: normal;
  }

  a {
    color: #2D3134;
  }

  .tl-edges {
    overflow-y: hidden;
  }
`;

export default GlobalStyle;

import styled from "styled-components";

const Header = styled.header`
  position: fixed;
  width: 100%;
  z-index: 1000;
  background-color: ${({ theme, scrolled }) =>
    scrolled ? theme.colors.darkGrey1 : "transparent"};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px ${({ menuOpen }) => (menuOpen ? "45px" : "30px")} 30px 30px;
  transition: transform 0.35s ease, background-color 0.35s ease;
  transform: ${({ scrolled, scrolledUp, showHeader }) =>
    (scrolled == 0 || scrolledUp) && showHeader
      ? "translateY(0)"
      : "translateY(-100%)"};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 30px;
  }

  .buttons {
    display: flex;
    align-items: center;
  }
`;

export default {
  Header,
};

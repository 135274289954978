import styled from 'styled-components';

const Container = styled.div`
    position: relative;
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
    height: 100%;
`

export default {
    Container
}
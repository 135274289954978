import React, { useState } from "react";
import { Link } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { INLINES, BLOCKS } from "@contentful/rich-text-types";
import QuotationMarksIcon from "../components/common/QuotationMarksIcon";
import ArrowIcon from "../components/common/ArrowIcon";
import { theme } from "../styles/theme";

const ParentMenuItem = ({ item, itemSlug, pageMap, props }) => {
  const [open, setOpen] = useState(false);

  return (
    <li className={`item parent ${open ? "open" : ""}`} key={item.id}>
      <button onClick={() => setOpen(!open)}>
        {item.title} <span>{open ? "-" : "+"}</span>
      </button>
      {item.items && createMenuStructure(item.items, itemSlug, props, pageMap)}
    </li>
  );
};

export const createMenuStructure = (
  items = [],
  slug = "",
  props = {},
  pageMap = []
): JSX.Element => {
  return (
    <ul>
      {items.map(item => {
        const itemSlug = slug + (item.slug === "home" ? "" : `/${item.slug}`);

        if (item.linkType === "external") {
          return (
            <li className="item" key={item.id}>
              <a href={item.externalLink}>{item.title}</a>
            </li>
          );
        } else if (item.linkType === "parent") {
          return (
            <ParentMenuItem
              item={item}
              key={item.id}
              itemSlug={itemSlug}
              pageMap={pageMap}
              props={props}
            />
          );
        }

        return (
          <li className="item" key={item.id}>
            <AniLink
              cover
              direction="up"
              bg={theme.colors.darkGrey1}
              to={getSlugByID({
                pageMap: pageMap,
                pageID: item.contentLink
                  ? item.contentLink.contentful_id
                  : null,
              })}
              {...props}
            >
              {item.title}
            </AniLink>
          </li>
        );
      })}
    </ul>
  );
};

export const getSlugByID = ({ pageMap, pageID }): string => {
  let page;

  if (pageMap && pageID) {
    page = pageMap.find(path => path.contentful_id == pageID);
  }

  return (page && page.slug) || "/";
};

export const contentfulRichTextLinks = ({ pageMap }) => {
  return {
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const linkPath = getSlugByID({
        pageMap,
        pageID: node.data.target.sys.contentful_id,
      });

      return <Link to={linkPath}>{children}</Link>;
    },
  };
};

export const contentfulAssetLinks = icon => {
  return {
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      return (
        <a
          style={{ display: "flex", alignItems: "center" }}
          target="__blank"
          href={node?.data?.target?.fields?.file?.url}
        >
          {icon && icon}
          {children}
        </a>
      );
    },
  };
};

export const contentfulRichTextLinksWithArrow = ({ pageMap, colourScheme }) => {
  return {
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const linkPath = getSlugByID({
        pageMap,
        pageID: node.data.target.sys.contentful_id,
      });

      return (
        <Link to={linkPath}>
          {children} <ArrowIcon colourScheme={colourScheme} />
        </Link>
      );
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <a href={node.data.uri}>
          {children} <ArrowIcon colourScheme={colourScheme} />
        </a>
      );
    },
  };
};

export const contentfulRichTextInlineImage = {
  [BLOCKS.EMBEDDED_ASSET]: node => {
    const { title, file } = node?.data?.target?.fields;
    const { url } = file["en-US"];

    if (!url) return null;

    return <img className="inline-image" src={url} alt={title["en-US"]} />;
  },
};

export const contentfulRichTextBlockquoteWithQuoteSVG = ({
  colourScheme,
  color,
}: {
  colourScheme?: string;
  color?: string;
}) => {
  return {
    [BLOCKS.QUOTE]: (node, children) => {
      const { content } = node;

      if (!content) return null;

      return (
        <blockquote>
          <QuotationMarksIcon color={color} colourScheme={colourScheme} />
          {children}
        </blockquote>
      );
    },
  };
};

export const truncateString = (string: string, length: number): string => {
  if (typeof string !== "string") return "";

  if (string.length <= length) {
    return string;
  }

  return string.slice(0, length) + "...";
};

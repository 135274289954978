import styled from "styled-components";
import AniLink from "gatsby-plugin-transition-link/AniLink";

const Logo = styled(AniLink)`
  position: relative;
  z-index: 1000;

  svg {
    height: 45px;
    width: auto;
  }
`;

export default {
  Logo,
};

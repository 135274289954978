import React from "react";
import { useTheme } from "styled-components";
import S from "./styles";
import { ColourScheme } from "../../../types";

interface QuotationMarksIconProps {
  color?: string;
  colourScheme?: ColourScheme;
  [prop: string]: any;
}

const QuotationMarksIcon = ({
  colourScheme,
  color,
  ...rest
}: QuotationMarksIconProps) => {
  const theme = useTheme();

  const strokeColour =
    colourScheme === "dark" ? theme.colors.white1 : theme.colors.darkGrey1;

  return (
    <S.QuotationMarksIcon
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="29"
      viewBox="0 0 32 29"
      {...rest}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g
          fill={color || strokeColour}
          fillRule="nonzero"
          transform="translate(-50 -907)"
        >
          <path
            d="M12.788 28.89V15.03h-5.94c0-2.22.42-4.095 1.26-5.625.84-1.53 2.4-2.685 4.68-3.465V0c-1.86.24-3.585.795-5.175 1.665A14.64 14.64 0 003.518 4.95C2.378 6.27 1.493 7.8.863 9.54c-.63 1.74-.915 3.6-.855 5.58v13.77h12.78zm19.17 0V15.03h-5.94c0-2.22.42-4.095 1.26-5.625.84-1.53 2.4-2.685 4.68-3.465V0c-1.86.24-3.585.795-5.175 1.665a14.64 14.64 0 00-4.095 3.285c-1.14 1.32-2.025 2.85-2.655 4.59-.63 1.74-.915 3.6-.855 5.58v13.77h12.78z"
            transform="translate(50 907)"
          ></path>
        </g>
      </g>
    </S.QuotationMarksIcon>
  );
};

export default QuotationMarksIcon;

import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { TransitionPortal } from "gatsby-plugin-transition-link";
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import { theme } from "../../styles/theme";
import GlobalStyle from "../../styles/GlobalStyle";

interface LayoutProps {
  children: React.ReactNode;
  pathContext?: any;
}

const Layout: React.FunctionComponent<LayoutProps> = ({
  children,
  pathContext,
}) => {
  useEffect(() => {
    document.body.classList.remove("fixed");
  }, []);
  
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <TransitionPortal>
          <Header />
      </TransitionPortal>
      <main>{children}</main>
      <Footer />
    </ThemeProvider>
  );
};

export default Layout;

import styled from "styled-components";

const FooterMenu = styled.nav`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: auto;
  flex: 3;

  @media screen and (max-width: ${({ theme}) => theme.breakpoints.small}) {
    width: 100%;
    flex-direction: column;
    margin-top: 40px;
  }

  .footer-menu {
    @media screen and (max-width: ${({ theme}) => theme.breakpoints.medium}) {
      width: 50%;
      margin-bottom: 40px;

      &:last-of-type {
        display: flex;
        width: 100%;
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: ${({ theme}) => theme.breakpoints.small}) {
      width: 100%;
      margin-bottom: 0;

      &:last-of-type {
        flex-direction: column;
      }
    }
  }

  .menu-inner {
    @media screen and (max-width: ${({ theme}) => theme.breakpoints.medium}) {
      flex: 1;
    }
  }

  .signup-social {
    margin-top: 20px;
    
    @media screen and (max-width: ${({ theme}) => theme.breakpoints.medium}) {
      flex: 1;

      form {
        width: 100%;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0 20px 0 0;
    margin: 0;
    position: relative;

    @media screen and (max-width: ${({ theme}) => theme.breakpoints.small}) {
      margin-bottom: 40px;
    }
  }

  li {
    &:last-child {
      a {
        margin-bottom: 0;
      }
    }
  }

  .title {
    font-family: ${({ theme}) => theme.fonts.poppinsSemiBold};
  }

  .title, 
  a {
    display: block;
    font-weight: normal;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.darkGrey1};
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.3px;
    margin-bottom: 15px;
  }

  .social {
    display: flex;
    align-items: flex-end;

    a {
      display: inline-block;
      align-items: flex-end;
      margin-right: 20px;
      margin-bottom: 0;

      &.linkedin {
        width: 22px;
        height: 22px;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      &.youtube {
        width: 26px;
        height: 18px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .copyright {
    font-size: 13px;
    margin-bottom: 0;
    margin-top: 80px;
  }

  a {
    font-family: ${({ theme}) => theme.fonts.poppinsRegular};

    img {
      max-width: 16px;
      max-height: 16px;
    }
  }
`;



export default {
  FooterMenu,
};

import React from "react";
import { useTheme } from "styled-components";
import S from "./styles";
import { ColourScheme } from "../../../types";

interface TickIconProps {
  color?: string;
  colourScheme?: ColourScheme;
  [prop: string]: any;
}

const TickIcon = ({ colourScheme, color, ...rest }: TickIconProps) => {
  const theme = useTheme();

  const strokeColour =
    colourScheme === "dark" ? theme.colors.white1 : theme.colors.darkGrey1;

  return (
    <S.TickIcon
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 -6 26 40"
      {...rest}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g
          stroke={color || strokeColour}
          strokeWidth="1.5"
          transform="translate(-1284 -1205)"
        >
          <path
            d="M1285 1216.97921L1293.94253 1228.28841 1311.95342 1209"
            transform="rotate(-15 1298.477 1218.644)"
          ></path>
        </g>
      </g>
    </S.TickIcon>
  );
};

export default TickIcon;

import styled from "styled-components";

const MainMenu = styled.nav`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white1};
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  padding-top: 95px;
  padding-bottom: 50px;
  transition: transform 0.5s ease;
  box-sizing: border-box;
  transform: ${({ open }) => (open ? "translateX(0%)" : "translateX(100%)")};
  overflow-y: scroll;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 99px 0 20px 0;
  }

  .container {
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      padding: 0;
    }
  }

  .inner {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 120px;
    transition: opacity 0.25s ease;
    opacity: ${({ open }) => (open ? 1 : 0)};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      flex-direction: column;
      padding: 0 20px;
      overflow-y: scroll;

      .right {
        order: 1;
      }

      .left {
        order: 2;
        text-align: right;
      }
    }
  }

  .connect {
    display: flex;
    margin: 0 20px;

    .box {
      margin-left: auto;
      padding: 0;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  li {
    &.parent {
      cursor: pointer;

      a {
        span {
          min-width: 14px;
          display: inline-block;
        }
      }

      ul {
        position: relative;
        display: block;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        left: 0;
        padding: 0;
        transition: all 0.35s ease;
        max-height: 0;
        z-index: 0;
        overflow: hidden;

        a {
          color: ${({ theme }) => theme.colors.lightGrey2};
          margin: 0;
          font-family: ${({ theme }) => theme.fonts.poppinsRegular};
          transition: color 0.2s ease;

          &:hover {
            color: ${({ theme }) => theme.colors.darkGrey1};
          }
        }
      }
    }

    &.open {
      ul {
        display: block;
        max-height: 1000px;
      }
    }
  }

  a,
  button {
    display: block;
    font-family: ${({ theme }) => theme.fonts.poppinsSemiBold};
    color: ${({ theme }) => theme.colors.darkGrey1};
    text-decoration: none;
    font-size: 24px;
    line-height: 50px;
    width: 100%;
  }

  button {
    text-align: right;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .social {
    a {
      display: inline-block;
      margin-right: 20px;
      width: auto;
    }
  }

  .linkedin {
    svg {
      width: 22px;
      height: 22px;
    }
  }

  .right {
    a {
      text-align: right;
    }
  }
`;

export default {
  MainMenu,
};

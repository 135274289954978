import React, { useContext } from "react";
import Container from "../Container";
import FooterMenu from "../FooterMenu";
import { SettingsContext } from "../../../context/SettingsContext";
import logo from "../../../assets/logos/jvat-logo-dark-green.svg";
import S from "./styles";
import FooterLogo from "../../common/FooterLogo";

const Footer = () => {
  const {
    settings: { iso9001CertificationLogo },
  } = useContext(SettingsContext);

  return (
    <S.Footer>
      <Container>
        <div className="inner">
          <div className="logos">
            <FooterLogo className="logo" />
            {iso9001CertificationLogo && (
              <img
                className="certified"
                src={iso9001CertificationLogo.fixed.src}
              />
            )}
          </div>
          <FooterMenu />
        </div>
      </Container>
    </S.Footer>
  );
};

export default Footer;

import styled from "styled-components";

const Footer = styled.footer`
  position: relative;
  padding: 80px 0;
  background-color: ${({ theme }) => theme.colors.lightGrey1};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 55px 0 35px 0;
  }

  .inner {
    display: flex;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      flex-direction: column;
    }
  }

  .logos {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .logo {
      align-self: flex-start;
      height: 45px;
      width: auto;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin-bottom: 40px;
      }

      img {
        height: 100%;
        width: 100%;
      }
    }

    .certified {
      align-self: flex-start;
      height: 76px;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }
`;

export default {
  Footer,
};

import React from "react";
import { useTheme } from "styled-components";
import S from "./styles";
import { ColourScheme } from "../../../types";

interface EnvelopeIconProps {
  color?: string;
  colourScheme?: ColourScheme;
  [prop: string]: any;
}

const EnvelopeIcon = ({ colourScheme, color, ...rest }: EnvelopeIconProps) => {
  const theme = useTheme();

  const strokeColour =
    colourScheme === "dark" ? theme.colors.white1 : theme.colors.darkGrey1;

  return (
    <S.EnvelopeIcon
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="27"
      viewBox="0 0 37 27"
      {...rest}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g
          stroke={color || strokeColour}
          strokeWidth="1.5"
          transform="translate(-1262 -44)"
        >
          <g transform="translate(69 40)">
            <g transform="translate(1194 5)">
              <g>
                <path d="M0 0H35V24.595H0z"></path>
                <path d="M0 4.70494514e-14L17.5 16.5870522 35 4.70494514e-14"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </S.EnvelopeIcon>
  );
};

export default EnvelopeIcon;

import React, { useState, useEffect, useContext } from "react";
import useScrollPosition from "@react-hook/window-scroll";
import MainMenu from "../MainMenu";
import S from "./styles";
import Logo from "../Logo";
import HamburgerButton from "../HamburgerButton";
import { usePrevious } from "../../../hooks/usePrevious";
import ConnectButton from "../ConnectButton";
import { HeaderContext } from "../../../context/HeaderContext";

const Header = () => {
  const scrolled = useScrollPosition(60);
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolledUp, setScrolledUp] = useState(false);
  const previous = usePrevious({ scrolled });
  const {
    headerColor,
    setHeaderColor,
    initialHeaderColor,
    showHeader,
    setShowHeader,
  } = useContext(HeaderContext);

  useEffect(() => {
    if (scrolled === 0) {
      setHeaderColor(initialHeaderColor);
      setShowHeader(true);
    } else if (previous && scrolled < previous.scrolled) {
      setScrolledUp(true);
      setHeaderColor("dark");
      setShowHeader(true);
    } else {
      setScrolledUp(false);
    }
  }, [scrolled]);

  return (
    <S.Header
      scrolled={scrolled}
      showHeader={showHeader}
      scrolledUp={scrolledUp}
      menuOpen={menuOpen}
    >
      <Logo
        onClick={() => {
          document.body.classList.remove("fixed");
          if (scrolled) {
            setHeaderColor("dark");
          } else {
            setHeaderColor(initialHeaderColor);
          }
          setMenuOpen(false);
        }}
      />
      <MainMenu
        open={menuOpen}
        setMenuOpen={setMenuOpen}
        scrolled={scrolled}
        type="mobile"
      />
      <div className="buttons">
        <ConnectButton colourScheme={headerColor} />
        <HamburgerButton
          colourScheme={headerColor}
          className={menuOpen ? "open" : ""}
          onClick={() => {
            if (menuOpen) {
              document.body.classList.remove("fixed");
              if (scrolled) {
                setHeaderColor("dark");
              } else {
                setHeaderColor(initialHeaderColor);
              }
            } else {
              document.body.classList.add("fixed");
              setHeaderColor("light");
            }
            setMenuOpen(!menuOpen);
          }}
        />
      </div>
    </S.Header>
  );
};

export default Header;

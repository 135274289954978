import React from "react";
import { useTheme } from "styled-components";
import S from "./styles";
import { ColourScheme } from "../../../types";

interface YouTubeIconProps {
  color?: string;
  colourScheme?: ColourScheme;
  [prop: string]: any;
}

const YouTubeIcon = ({ colourScheme, color, ...rest }: YouTubeIconProps) => {
  const theme = useTheme();

  const strokeColour =
    colourScheme === "dark" ? theme.colors.white1 : theme.colors.darkGrey1;

  return (
    <S.YouTubeIcon
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="19"
      viewBox="0 0 26 19"
      {...rest}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={color || strokeColour} fillRule="nonzero" transform="translate(-182 -413)">
          <g transform="translate(140 220)">
            <g transform="translate(1 116)">
              <g transform="translate(41 77.5)">
                <path d="M24.608 2.522S24.114.695 21.822.6c0 0-6.507-.266-8.668-.172-2.188-.085-8.526.127-8.526.127C1.99.63 1.57 2.5 1.57 2.5S.8 4.515.75 9.123c0 0-.066 3.606.798 6.368 0 0 .428 1.707 2.785 1.921 0 0 6.675.254 8.837.16 2.189.086 8.357-.115 8.357-.115 2.638-.072 3.055-1.946 3.055-1.946s.772-2.014.822-6.623c0 0 .064-3.605-.797-6.366zm-13.07 11.144V4.203l7.1 4.755-7.1 4.708z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </S.YouTubeIcon>
  );
};

export default YouTubeIcon;

import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const ConnectButton = styled(AniLink)`
    position: relative;
    color: ${({ theme, scrolled, colourScheme }) => scrolled ? theme.colors.white1 : colourScheme === "dark" ? theme.colors.white1 : theme.colors.darkGrey1};
    font-family: ${({ theme}) => theme.fonts.poppinsRegular};
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 15px;
    line-height: 30px;
    transition: color 0.5s ease;

    .box {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        margin-left: 0px;
    }

    svg {
        width: 40px;
        height: 40px;
    }
`

export default {
    ConnectButton
}
import React, { useState, useContext } from "react";
import { SettingsContext } from "../../../context/SettingsContext";
import MailchimpSubscribe from "react-mailchimp-subscribe"
import EnvelopeIcon from "../EnvelopeIcon";
import ArrowIcon from "../ArrowIcon";
import TickIcon from "../TickIcon";
import SpinnerIcon from "../SpinnerIcon";
import S from "./styles";

const NewsletterSignup = () => {
  const {
    settings: { mailchimpSubscribeEndpoint },
  } = useContext(SettingsContext);
  const [email, setEmail] = useState("");
  const [focused, setFocused] = useState(false);

  return (
    <MailchimpSubscribe
      url={mailchimpSubscribeEndpoint}
      render={({ subscribe, status, message }) => (
        <S.NewsletterSignup onSubmit={(e) => {
          e.preventDefault();
          subscribe({ EMAIL: email });
        }}>
        <EnvelopeIcon
          style={{
            transform: focused ? "translateX(-59px)" : "translateX(0px)",
            opacity: focused ? 0 : 1,
            position: "absolute",
            transition: "all 0.2s ease",
          }}
          fill="dark"
        />
        <input
          type="email"
          placeholder={
            status === "success" ? "Thanks for subscribing" : "Subscribe to Insights"
          }
          name="email"
          disabled={status === "sending" || status === "success"}
          onChange={e => setEmail(e.target.value)}
          value={email}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          style={{
            paddingLeft: focused ? 0 : "59px",
            transition: "all 0.2s ease",
          }}
        />
        <button className="submit" disabled={status === "sending" || status === "success" || !email}>
          <SpinnerIcon
            style={{
              position: "absolute",
              opacity: status === "sending" ? 1 : 0,
              transition: "all 0.1s ease",
            }}
          />
          <ArrowIcon
            colourScheme="light"
            style={{
              position: "absolute",
              opacity: status === "sending" || status === "success" ? 0 : 1,
              transition: "all 0.1s ease",
            }}
          />
          <TickIcon
            style={{
              position: "absolute",
              opacity: status === "success" ? 1 : 0,
              transition: "all 0.1s ease",
            }}
          />
        </button>
        {status === "error" && <div className="error">{message}</div>}
      </S.NewsletterSignup>
      )}
    />
  );
};

export default NewsletterSignup;
